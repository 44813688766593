import { IFieldTooltip } from "@forms/schema";
import { ITooltip } from "apps/website/components/feature/Tooltip/Tooltip";

import { renderStoryBlokRichText } from "./text";

export const getStoryBlokTooltip = (
  tooltip: [IFieldTooltip],
  interpolationValues: Record<string, string>,
): ITooltip | undefined => {
  if (!tooltip?.length) return undefined;
  console.log(tooltip[0].body);
  return {
    ...tooltip[0],
    buttonName: tooltip[0].label,
    body: renderStoryBlokRichText(tooltip[0].body, {
      interpolation: {
        values: interpolationValues,
      },
    }),
  };
};
