import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  groupedFieldSpanObjectMap,
  OptionColumnSize,
} from "apps/website/maps/Form.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import useA11y from "apps/website/hooks/useA11y";
import Tooltip, {
  ITooltip,
} from "apps/website/components/feature/Tooltip/Tooltip";
import { Align, Display } from "apps/website/components/base/Text/Text.map";
import Information from "apps/website/components/form/Information/Information";

import Checkbox, { ICheckboxOption } from "../Checkbox";
import { FontStyle } from "../../Label/Label.map";
import Label from "../../Label/Label";

export interface ICheckboxGroup {
  label: string;
  options: ICheckboxOption[];
  optionSize?: OptionColumnSize;
  labelPosition?: Align;
  hideLabel?: boolean;
  labelStyle?: FontStyle;
  onChange(value: string[]): void;
  optional?: boolean;
  component?: string;
  className?: string;
  children?: ReactNode;
  tabbable?: boolean;
  tooltip?: ITooltip;
  display?: Display;
  description?: string;
  maximum?: number;
}

const CheckboxGroup: FC<ICheckboxGroup> = ({ label, labelStyle = "default", options, optionSize = "full", optional, hideLabel = false, className, onChange, component = "CheckboxGroup", tabbable = true, children, tooltip, display, description, maximum, labelPosition = "default" }) => {
  const { UUID } = useA11y();
  const [ checkedOptions, setCheckedOptions ] = useState<string[]>(
    options.filter((option) => !!option.selected).map((option) => (option.value as string)),
  );
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      onChange(checkedOptions);
    }
    isMounted.current = true;
  }, [ checkedOptions ]);

  const handleToggleCheckedOption = useCallback((value: string) => {
    if (checkedOptions.find((option) => option === value)) {
      setCheckedOptions(checkedOptions.filter((option) => option !== value));
    } else if (!maximum || checkedOptions.length < maximum) {
      setCheckedOptions([ ...checkedOptions, value ]);
    }
  }, [ checkedOptions, maximum ]);

  return (
    <div data-component={component} className={className} data-theme="form-input">
      { description && (<Information description={description} style="default" />) }
      <Label
        label={label}
        align={labelPosition}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
      />
      <Grid>
        { options.map((option) => (
          <Column key={option.value} spans={groupedFieldSpanObjectMap[optionSize]} >
            <>
              <Checkbox
                name={option.value ?? ""}
                label={option.name}
                value={option.value}
                advancedOptions={option.advancedOptions}
                checked={!!checkedOptions?.find((checkedOption) => checkedOption === option.value)}
                onChange={(event) => handleToggleCheckedOption(event.target.value)}
                className="w-full"
                display={display}
                tabbable={tabbable}
                disabled={checkedOptions.length >= (maximum ?? 1)}
              />
            </>
          </Column>
        ))
        }
      </Grid>
      { children }
      { tooltip && (
        <div className="h-4 pt-2">
          <Tooltip id={UUID} {...tooltip} />
        </div>
      ) }
    </div>
  );
};

export default CheckboxGroup;
