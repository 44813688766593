import {
  FC,
  KeyboardEvent,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { WithTestID } from "apps/website/types";
import { ITooltip } from "apps/website/components/feature/Tooltip/Tooltip";
import { FontStyle } from "apps/website/components/form/Label/Label.map";
import Input from "apps/website/components/form/Input/Input";
import List from "apps/website/components/base/List/List";
import { themeRootClassMap } from "apps/website/maps/Theme.map";

import IconButton from "../../base/IconButton/IconButton";
import { Align, legacySizeCollectionMap } from "../../base/Text/Text.map";
import Text from "../../base/Text/Text";
import Spacer from "../../layout/Spacer/Spacer";

import TypeToSelectMessage, {
  ITypeToSelectOptionMessage,
} from "./TypeToSelectOptionMessage/TypeToSelectOptionMessage";

export interface ITypeToSelectOption {
  title: string;
  value: string;
  aliases: string[];
  optionMessage?: ITypeToSelectOptionMessage;
  selected: boolean;
}
export interface ITypeToSelect extends WithTestID {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  label: string;
  labelStyle?: FontStyle;
  labelPosition?: Align;
  hideLabel?: boolean;
  optional?: boolean;
  onChange(options: ITypeToSelectOption[]): void;
  className?: string;
  tooltip?: ITooltip;
  options: ITypeToSelectOption[];
  optionsGroupMessage?: ITypeToSelectOptionMessage;
}
const TypeToSelect: FC<ITypeToSelect> = ({
  disabled,
  name,
  placeholder,
  label,
  labelStyle,
  labelPosition = "default",
  hideLabel,
  optional,
  onChange,
  className,
  tooltip,
  options,
  optionsGroupMessage,
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const optionsPanelRef = useRef<HTMLDivElement | null>(null);
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const selectableOptionsRef = useRef<Record<string, HTMLButtonElement>>({});
  const selectedOptionsRef = useRef<Record<string, HTMLButtonElement | HTMLAnchorElement>>({});
  const [ searchTerm, setSearchTerm ] = useState<string>("");
  const [ availableOptions, setAvailableOptions ] = useState<ITypeToSelectOption[]>([]);
  const [ selectableOptions, setSelectableOptions ] = useState<ITypeToSelectOption[]>(
    options.filter((opt) => !opt.selected),
  );
  const [ selectedOptions, setSelectedOptions ] = useState<ITypeToSelectOption[]>(
    options.filter((opt) => opt.selected),
  );
  useEffect(() => {
    handleSearch();
  }, [ searchTerm ]);
  useEffect(() => {
    setSelectableOptions(
      availableOptions
        .filter((availableOption) => !selectedOptions.map(
          (selectedOption) => selectedOption.value,
        ).includes(availableOption.value)),
    );
  }, [ selectedOptions, availableOptions ]);
  const handleSearch = useCallback(() => {
    if (!searchTerm.length) {
      setAvailableOptions(options);
      return;
    }
    const getAvailableOptions = options.filter((option) => {
      let isMatch = false;
      for (const alias of option.aliases) {
        if (alias.includes(searchTerm.toLocaleLowerCase())) {
          isMatch = true;
        }
      }
      return isMatch;
    });
    setAvailableOptions(getAvailableOptions);
  }, [ searchTerm, options ]);
  const onInputKeyDown = (event: KeyboardEvent<Element>) => {
    const hijackedKeys = [ "ArrowDown" ];
    if (hijackedKeys.includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === "ArrowDown") {
      focusOnNextOptionByIndex(0);
    } else if (event.key === "Tab") {
      focusOnFirstTag();
    }
  };
  const onOptionKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const hijackedKeys = [ "ArrowUp", "ArrowDown", "Enter" ];
    if (hijackedKeys.includes(event.key)) {
      event.preventDefault();
    }
    const currentValue = (event.target as HTMLButtonElement).getAttribute("data-value");
    const currentIndex = +((event.target as HTMLButtonElement).getAttribute("data-index") ?? 0);
    const previousIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;
    if (event.key === "ArrowUp") {
      if (currentIndex <= 0) {
        focusOnSearchBar();
        return;
      }
      focusOnNextOptionByIndex(previousIndex);
    } else if (event.key === "ArrowDown") {
      if (nextIndex < availableOptions.length) {
        focusOnNextOptionByIndex(nextIndex);
      }
    } else if (event.key === "Enter") {
      const selectedOption = availableOptions.find((option) => option.value === currentValue);
      onAddOptionToSelectedOptions(selectedOption);
    } else if (event.key === "Tab") {
      focusOnFirstTag();
    }
  };
  const focusOnNextOptionByIndex = (index: number) => {
    if (selectableOptionsRef.current[index]) {
      selectableOptionsRef.current[index].focus();
    }
  };
  const focusOnSearchBar = () => {
    searchBarRef.current?.focus();
  };
  const focusOnComponent = () => {
    componentRef.current?.setAttribute("tabindex", "0");
    componentRef.current?.focus();
    componentRef.current?.removeAttribute("tabindex");
  };
  const focusOnFirstTag = () => {
    selectedOptionsRef.current[0].focus();
  };
  const onAddOptionToSelectedOptions = (option?: ITypeToSelectOption) => {
    if (option && !selectedOptions.find((findOption) => option.value === findOption.value)) {
      setSelectedOptions([ ...selectedOptions, option ]);
      setSearchTerm("");
      focusOnComponent();
    }
  };
  const onRemoveOptionFromSelectedOptions = (option: ITypeToSelectOption) => {
    setSelectedOptions([
      ...selectedOptions
        .filter(
          (filteredOption) => filteredOption.value !== option.value,
        ),
    ]);
  };
  useEffect(() => {
    onChange(selectedOptions);
  }, [ selectedOptions ]);
  return (
    <div data-component={TypeToSelect.name} className="relative" ref={componentRef}>
      <div className="group/input">
        <Suspense>
          <Input
            disabled={disabled}
            name={name}
            value={searchTerm}
            placeholder={placeholder}
            label={label}
            labelStyle={labelStyle}
            labelPosition={labelPosition}
            hideLabel={hideLabel}
            optional={optional}
            className={className}
            onChange={(event) => setSearchTerm(event.target.value)}
            onKeyDown={onInputKeyDown}
            tooltip={tooltip}
            autocomplete="off"
            data-testid="search"
            ref={searchBarRef}
          />
        </Suspense>
        <div
          ref={optionsPanelRef}
          className={"overflow-auto absolute p-4 w-full max-h-48 z-40 bg-white hidden group-focus-within/input:block"}
        >
          <List component="TypeToSelectList">
            { selectableOptions.map((option, index) => (
              <li key={option.value} tabIndex={-1} value={option.value} className="border-b-2 border-b-light-grey mb-2 pb-2 last:border-b-0 last:pb-0 last:mb-0">
                <button
                  tabIndex={0}
                  data-index={index}
                  data-value={option.value}
                  ref={(selectableOption) => {
                    if (selectableOption) {
                      selectableOptionsRef.current[index] = (selectableOption);
                    }
                  }}
                  onKeyDown={onOptionKeyDown}
                  onClick={() => onAddOptionToSelectedOptions(option)}
                  className="w-full text-left"
                >
                  <Text tag="span">
                    { option.title }
                  </Text>
                </button>
              </li>
            )) }
            { !selectableOptions.length && (
              <li>
                <Text tag="span">
                  No results
                </Text>
              </li>
            ) }
          </List>
        </div>
      </div>
      <List direction={optionsGroupMessage ? "row" : "column"}>
        { selectedOptions.map((option, index) => (
          <li key={option.value} data-theme="dark" className={"mb-4 pt-2"}>
            <IconButton
              icon="cross"
              label={`Remove ${option.title}`}
              color="default"
              size="xsmall"
              className={`px-2 ${themeRootClassMap.dark}`}
              display="title"
              textSize={legacySizeCollectionMap.titleXs}
              onClick={() => onRemoveOptionFromSelectedOptions(option)}
              height="fit"
              ref={(selectedOption) => {
                if (selectedOption) {
                  selectedOptionsRef.current[index] = (selectedOption);
                }
              }}
            >
              { option.title }
            </IconButton>
            { !optionsGroupMessage && (
              <>
                <Spacer size="md" />
                <TypeToSelectMessage {...{ icon: option.optionMessage?.icon ?? "success", body: option.optionMessage?.body ?? "" } } />
              </>
            ) }
          </li>
        )) }
      </List>
      { (optionsGroupMessage && !!selectedOptions.length) && (
        <>
          <Spacer size="md" />
          <TypeToSelectMessage {...optionsGroupMessage} />
        </>
      ) }
    </div>
  );
};
export default TypeToSelect;
