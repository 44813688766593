import { ChangeEvent, FC, ReactNode } from "react";

import useA11y from "apps/website/hooks/useA11y";

import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { FontStyle } from "../Label/Label.map";
import Label from "../Label/Label";
import { Align } from "../../base/Text/Text.map";
import { IconSvg } from "../../base/Icon/Icon.map";
import Icon from "../../base/Icon/Icon";

export interface IReactionSelectProps {
  label: string;
  name: string;
  optional?: boolean;
  hideLabel?: boolean;
  labelPosition?: Align;
  labelStyle?: FontStyle;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  component?: string;
  className?: string;
  children?: ReactNode;
  tabbable?: boolean;
  tooltip?: ITooltip;
  selected?: string;
}

export type TReactionValue = "POSITIVE" | "NEGATIVE";
interface IReaction {
  icon: IconSvg;
  iconSelected: IconSvg;
  value: TReactionValue;
  label: string;
}

const REACTIONS: IReaction[] = [
  {
    icon: "thumbsUp",
    iconSelected: "thumbsUpFilled",
    value: "POSITIVE",
    label: "Rate positively",
  },
  {
    icon: "thumbsDown",
    iconSelected: "thumbsDownFilled",
    value: "NEGATIVE",
    label: "Rate negatively",
  },
];

const ReactionSelect: FC<IReactionSelectProps> = ({ onChange, name, label, className, hideLabel = false, component = "ReactionSelect", tabbable = true, children, tooltip, labelPosition = "default" }) => {
  const { UUID } = useA11y();

  return (
    <div data-component={component} className={`flex ${className}`} data-theme="form-input">
      <Label
        label={label}
        align={labelPosition}
        fontStyle="body"
        hideLabel={hideLabel}
        UUID={UUID}
        optional={true}
        className="flex items-center"
      />
      <div className="flex space-x-4 justify-between">
        <>
          { REACTIONS.map((reaction) => (
            <label key={reaction.value} className={"relative flex flex-1 flex-wrap space-x-1 items-center group" }>
              <input
                type="radio"
                id={UUID}
                name={name}
                value={reaction.value}
                onChange={onChange}
                className={"absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none outline-none cursor-pointer"}
                tabIndex={tabbable ? 0 : -1}
              />

              <span className="sr-only">{ reaction.label }</span>

              <Icon icon={reaction.icon} size="xsmall" className="block peer-checked:hidden"/>
              <Icon icon={reaction.iconSelected} size="xsmall" className="hidden peer-checked:block"/>
            </label>
          ))
          }
        </>
      </div>
      { children }
      { tooltip && (
        <div className="h-4 pt-2">
          <Tooltip id={UUID} {...tooltip} />
        </div>
      ) }
    </div>
  );
};

export default ReactionSelect;
