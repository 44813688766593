"use client";

/* eslint-disable react/display-name */
import "react-datepicker/dist/react-datepicker.css";

import dayjs, { Dayjs } from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import {
  FC,
  LegacyRef,
  forwardRef,
  MouseEvent,
} from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/24/outline";

import { Button } from "../Button/Button";

interface Props {
  value?: Dayjs;
  onChange(_: Dayjs): void;
  placeholder: string;
  minDate?: Dayjs;
  filter?: (_: Dayjs) => boolean;
}

interface PickerButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

dayjs.extend(AdvancedFormat);

export const DateInput: FC<Props> = ({
  value,
  onChange,
  placeholder,
  minDate,
  filter,
}) => {

  // This is pretty inefficient, but the only way to get around
  // the library's API
  const DatePickerButton = forwardRef(({
    onClick,
  }: PickerButtonProps, ref: LegacyRef<HTMLButtonElement>) => (
    <Button
      data-component={DatePickerButton.name}
      ref={ref}
      variant={value ? "primary" : "tertiary"}
      Icon={() => <CalendarIcon width={20} color={value ? "white" : "black"} className="mr-1"/>}
      // @ts-ignore
      onClick={onClick}
    >
      { value ? value.format("dddd Do MMM 'YY") : placeholder }
    </Button>
  ));

  // @ts-ignore
  return <DatePicker
    minDate={minDate ? minDate.toDate() : undefined}
    selected={value?.toDate()}
    onChange={(newDate: Date) => onChange(dayjs(newDate))}
    dateFormat={"dddd MM yy"}
    // @ts-ignore
    customInput={<DatePickerButton placeholder={placeholder} />}
    filterDate={filter === undefined
      ? undefined
      : (d: Date) => filter(dayjs(d))
    }
  />;
};
