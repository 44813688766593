import { FC, useCallback } from "react";

import {
  AdditionalFieldData,
  CheckboxGroupField,
  SelectOptionComponent,
} from "libs/forms-schema/src";
import { OptionColumnSize } from "apps/website/maps/Form.map";
import CheckboxGroup from
  "apps/website/components/form/Checkbox/CheckboxGroup/CheckboxGroup";
import {
  FieldData,
  getFlowFieldValuesForInterpolation,
} from "libs/state/src/lib/stores/useFormServiceStore";
import { getStoryBlokTooltip } from "apps/website/utils/storyblok/forms";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";

export interface IFormServiceCheckboxGroup {
  flowId: string;
  linkingId: string;
  field: CheckboxGroupField;
  optionSize: OptionColumnSize;
  optional?: boolean;
  onChange(value: FieldData): void;
  selected?: FieldData;
}

export const FormServiceCheckboxGroup: FC<IFormServiceCheckboxGroup> = ({
  flowId,
  linkingId,
  field,
  onChange,
  optional,
  optionSize,
  selected,
}) => {
  const handleSelect = useCallback((selectedValue: string[]) => {
    const actualSelectedOptions = field.options.map((option) => ({
      isSelected: option.selected,
      optValue: option.value,
      title: option.title,
      additionalData: option.additionalData,
    })).filter((opt) => selectedValue.some((sv) => sv === opt.optValue as string));
    onChange({
      submitValue: actualSelectedOptions.map((aso) => aso.optValue),
      displayValue: actualSelectedOptions.map((aso) => aso.title),
      additionalData: actualSelectedOptions.map((aso) => (aso.additionalData))
        .filter((ao) => !!ao).map((a) => a as AdditionalFieldData), // Not sure why we need this cast here
    });
  }, [ field.options, onChange ]);

  const isSelected = useCallback(
    (optionToCheck: SelectOptionComponent<string>) => {
      if (selected?.submitValue && Array.isArray(selected.submitValue)) {
        return selected.submitValue?.some((sv) => sv === optionToCheck.value);
      } if (!selected?.submitValue) {
        return optionToCheck.selected;
      }
      return optionToCheck.value === selected?.submitValue;
    },
    [ selected?.submitValue ],
  );

  return (
    <>
      <CheckboxGroup
        labelStyle={ field.label_position === "center" ? "title" : "alternative" }
        label={field.label ?? ""}
        options={field.options.map((option) => ({
          value: option.value,
          name: interpolateString(
            option.title,
            getFlowFieldValuesForInterpolation(flowId, linkingId, "GET_RELATED"),
            Interpolator.FRONTEND,
          ),
          advancedOptions: option.advanced_options,
          icon: option.icon,
          selected: isSelected(option),
        }))}
        optionSize={optionSize}
        optional={optional}
        hideLabel={field.hide_label}
        onChange={(value) => handleSelect(value)}
        display={field.display}
        tooltip={field.tooltip ? getStoryBlokTooltip(
          field.tooltip,
          getFlowFieldValuesForInterpolation(flowId, linkingId, field.tooltip?.[0]?.interpolation_behavior),
        ) : undefined}
        description={field.description}
        maximum={ !field.max_selected ? field.allowMultipleSelections ? 10 : 1 : field.max_selected } // Arbitrary number for now}
      />
    </>
  );
};
